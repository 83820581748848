import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AlertController, ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { StorageService } from 'src/app/shared/storage.service';
import { BasicEventModel, EventModel, EventNotifications } from './event.model';
import { InfoComponent } from 'src/app/shared/info/info.component';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0, overflow: 'hidden', 'max-height': '0px'}),
        animate('400ms', style({'max-height': '800px', overflow: 'hidden', opacity: 1}))
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(300, style({opacity: 0, height: '0px'})))
    ])
  ]
})
export class EventComponent implements OnInit, OnDestroy {
  @Input() event: BasicEventModel;
  eventLoading = true;
  timeToExpiration: number;
  displayTimeToExpiration: string;
  progressValue: number;
  expirationDate: any;
  expirationTime: any;
  dateFormat = 'DD.MM.YYYY';
  timeFormat: any[] = [24, 'HH:mm'];
  doneDate: any;
  doneTime: any;
  showEdit = false;
  progressColor: string; // ['red', 'orange', 'green']
  formChange = false;
  eventNotifications: EventNotifications[] = [];
  eventHasNotifications = true;
  app = true;
  notificationsChecked = false; // check pending notifications in phone
  notificationsLoaded = false;  // check event notifications
  saving = false;
  savingSubscription: Subscription;

  constructor(
    private storage: StorageService,
    public alertController: AlertController,
    public toastController: ToastController,
    public modalController: ModalController,
  ) {
    if(this.storage.settings.dateFormat === '2'){
      this.dateFormat = 'MM.DD.YYYY';
    }
    if(this.storage.settings.timeFormat === '2'){
      this.timeFormat = [12, 'hh:mm a'];
    }
  }

  ngOnInit() {
    this.app = this.storage.app;
    this.progressValue = 1;
    this.savingSubscription = this.storage.saving.subscribe(x => {
      if(this.saving && !x){
        setTimeout(() => {
          this.saving = x;
        },2000);
      }
    });
    this.calculateExpirationValue();
    // check event notifications
    this.checkEventNotifications();
  }

  calculateExpirationValue(){
    const currentTimestamp = moment();
    const futureTimestamp = moment(this.event.expirationTimestamp);

    if(currentTimestamp > futureTimestamp && this.event.status === 'active'){
      this.onStatusChange('expired');
    }

    if(this.event.status !== 'done'){
      this.expirationDate = futureTimestamp.format(this.dateFormat);
      this.expirationTime = futureTimestamp.format(this.timeFormat[1]);
    }

    if(this.event.status === 'done'){
      this.doneDate = moment(this.event.markedAsDone).format(this.dateFormat);
      this.doneTime = moment(this.event.markedAsDone).format(this.timeFormat[1]);
    }

    if(this.event.status === 'active'){
      this.timeToExpiration = futureTimestamp.diff(currentTimestamp, 'days');
      this.setProgress();
      this.displayExpTime();
    }

    this.eventLoading = false;
  }

  checkEventNotifications(){
    if(this.app){
      this.storage.getPendingNotifications().then(pendingNotifications =>
        {
          if(pendingNotifications.notifications.length > 0){
            this.eventHasNotifications = true;
          }else{
            // event has no notifications
            this.eventHasNotifications = false;
          }
        }
      );
    }
  }

  setProgress(){
    const expirationBreakpoint = this.storage.settings.greenZone; // from settings

    if(this.timeToExpiration <= expirationBreakpoint){
      this.progressValue = this.timeToExpiration/expirationBreakpoint;
      this.progressColor = 'green';
    }
    if(this.timeToExpiration <= this.storage.settings.orangeZone){
      this.progressColor = 'orange';
    }
    if(this.timeToExpiration <= this.storage.settings.redZone){
      this.progressColor = 'red';
    }
  }

  displayExpTime(){
    if(this.timeToExpiration > 999){
      this.displayTimeToExpiration = '999+';
    }else{
      this.displayTimeToExpiration = this.timeToExpiration.toString();
    }
  }

  onStatusChange(newStatus: string){
    let event: EventModel;
    const currentTimestamp = moment().valueOf();
    if(newStatus === 'expired'){
      this.saving = true;
      this.storage.get(this.event.timestamp)
        .then(eventRet => event = eventRet).catch(e => console.log('error: ', e))
        .then(() => {
          event.status = newStatus;
          event.markedAsDone = currentTimestamp;
          event.editTimestamp = currentTimestamp;
          event.synced = false;
          event.notifications = [];
          this.storage.set(this.event.timestamp, event);
          this.presentToast('Event EXPIRED!', 'Event: ' + event.name);
        }).catch(e => console.log('error: ', e));
    }else if(newStatus === 'done'){
      this.presentAlert('Mark this event as DONE?').then(result => {
        if(result === 'confirm'){
          this.saving = true;
          this.storage.get(this.event.timestamp)
            .then(eventRet => event = eventRet).catch(e => console.log('error: ', e))
            .then(() => {
              event.status = newStatus;
              event.markedAsDone = currentTimestamp; // When marked Done
              event.editTimestamp = currentTimestamp;
              event.synced = false;
              event.notifications = [];
              this.storage.set(this.event.timestamp, event);
              this.presentToast('Event set as DONE', 'Event: ' + event.name);
            }).catch(e => console.log('error: ', e));
        }
      });
    }else{
      this.presentAlert('REMOVE this event forever?').then(result => {
        if(result === 'confirm'){
          this.saving = true;
          this.storage.get(this.event.timestamp)
            .then(eventRet => event = eventRet).catch(e => console.log('error: ', e))
            .then(() => {
              event.status = newStatus;
              event.markedAsDone = currentTimestamp;
              event.editTimestamp = currentTimestamp;
              event.synced = false;
              event.notifications = [];
              this.storage.set(this.event.timestamp, event);
              this.presentToast('Event REMOVED!', 'Yep, It\'s gone...');
            }).catch(e => console.log('error: ', e));
        }
      });
    }
  }

  onStatusChangeOut(){
    this.saving = true;
  }

  toggleEdit(){
    if(this.showEdit && this.formChange){
      this.presentAlert('Leave without saving changes?').then(result => {
        if(result === 'confirm'){
          this.showEdit = !this.showEdit;
          this.formChange = false;
        }
      });
    }else{
      this.showEdit = !this.showEdit;
    }
    if(this.showEdit && !this.notificationsChecked){
      this.notificationsChecked = true;
      this.checkEventNotifications();
    }
    if(this.showEdit && !this.notificationsLoaded){
      console.log('loading event notifications');
      this.loadEventNotifications().then(() => {
        this.eventHasNotifications = true;
        console.log('loaded event notifications: ', this.eventNotifications);
      });
    }
  }

  loadEventNotifications(): Promise<void>{
    return this.storage.get(this.event.timestamp).then(event => {
      console.log('loadeded event: ', event);
      this.eventNotifications = event.notifications;
      this.notificationsLoaded = true;
    });
  }

  onFormChange(event){
    this.formChange = event;
  }

  onFormConfirm(){
    this.saving = true;
  }

  async toInfo(){
    const modal = await this.modalController.create({
      component: InfoComponent,
      componentProps: {
        info: 'notifications',
        notifications: this.eventNotifications,
        displayFormat: this.dateFormat + ' ' + this.timeFormat[1]
      },
      cssClass: 'for-settings',
    });
    return await modal.present();
  }

  setNotifications(){
    this.storage.calculateNotifications(
      this.event.notificationId, this.event.name, this.event.expirationTimestamp, this.event.timestamp
    ).then(
      () => this.checkEventNotifications()
    );
  }

  ngOnDestroy(): void {
      this.savingSubscription.unsubscribe();
  }

  // First show alert -> if OK execute function
  async presentAlert(message: string) {

    const alert = await this.alertController.create({
      header: 'Sure?',
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },{
          text: 'OK',
          role: 'confirm',
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }

  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
}
