import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { LocationService } from '../location.service';
import { MenuComponent } from '../menu/menu.component';
import { StorageService } from '../storage.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{

  nightMode = false;
  currentLocation: string;

  constructor(
    public popoverController: PopoverController,
    private storage: StorageService,
    public location: LocationService,
    private router: Router
  ) {}

  ngOnInit() {
    if(this.storage.settings.nightMode === '3'){
      this.nightMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      console.log(window.matchMedia('(prefers-color-scheme: dark)'));
    }else if(this.storage.settings.nightMode === '2'){
      this.nightMode = true;
      console.log(window.matchMedia('(prefers-color-scheme: dark)'));
    }
    if(this.nightMode){
      document.body.classList.add('dark');
    }
    this.location.location.subscribe(currentLocation => {
      this.currentLocation = currentLocation;
    });
  }

  goHome(){
    this.router.navigate(['']);
    this.location.setLocation('home');
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MenuComponent,
      event: ev,
      translucent: true
    });
    await popover.present();
  }

  public onModeToggle(){
    document.body.classList.toggle('dark');
    this.nightMode = !this.nightMode;
  }
}
