import { Component, OnInit } from '@angular/core';
import { UserModel } from '../auth/user.model';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss'],
})
export class StateComponent implements OnInit {
  network: string[];
  user: UserModel;

  constructor(private storage: StorageService) { }

  ngOnInit() {
    this.storage.user.subscribe(user => this.user = user);
    this.storage.network.subscribe(network => this.network = network);
  }

}
