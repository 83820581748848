import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomePage } from './home.page';

import { HomePageRoutingModule } from './home-routing.module';
import { FiltersComponent } from './filters/filters.component';
import { EventsComponent } from './events/events/events.component';
import { EventComponent } from './events/event/event.component';
import { NewFormComponent } from '../shared/forms/new-event.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { SettingsComponent } from '../shared/settings/settings.component';
import { InfoComponent } from '../shared/info/info.component';
import { CustomDateAdapter } from '../shared/forms/custom-date-adapter';
import { AuthComponent } from '../shared/auth/auth.component';
import { StateComponent } from '../shared/state/state.component';
import { PremiumComponent } from '../shared/premium/premium.component';
import { PoliciesComponent } from '../policies/policies.component';
import { HeaderModule } from '../shared/header/header.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderModule,
    HomePageRoutingModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatTimepickerModule,
  ],
  providers:[
    CustomDateAdapter,
    { provide: DateAdapter, useClass: CustomDateAdapter},
  ],
  declarations: [
    HomePage,
    FiltersComponent,
    EventsComponent,
    EventComponent,
    NewFormComponent,
    SettingsComponent,
    InfoComponent,
    AuthComponent,
    StateComponent,
    PremiumComponent,
    PoliciesComponent,
  ]
})
export class HomePageModule {}
