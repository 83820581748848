import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SetFiltersComponent } from 'src/app/shared/filters/setfilters.component';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0, overflow: 'hidden', 'max-height': '0px'}),
        animate('400ms', style({'max-height': '800px', overflow: 'hidden', opacity: 1}))
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(300, style({opacity: 0, height: '0px'})))
    ])
  ]
})
export class FiltersComponent implements OnInit {
  formShow = false;

  constructor(
    public popoverController: PopoverController) {
  }

  ngOnInit() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: SetFiltersComponent,
      event: ev,
      translucent: true
    });
    await popover.present();
  }

  toggleForm(){
    this.formShow = !this.formShow;
    console.log('toggle form triggering');
  }
}
