import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { EventNotifications } from 'src/app/home/events/event/event.model';
import { LocationService } from '../location.service';
import { PremiumComponent } from '../premium/premium.component';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit{
  @Input() info: string;
  @Input() notifications: EventNotifications[];
  @Input() displayFormat: string;

  constructor(
    public modalController: ModalController,
    private router: Router,
    private location: LocationService){}

  ngOnInit(){
    if(this.displayFormat){
      this.displayFormat = this.displayFormat.replace('DD', 'dd');
    }
  }

  goToPolicies(){
    this.router.navigate(['policies']);
    this.location.setLocation('policies');
    this.modalController.dismiss();
  }

  async toPremium(){
    const modal = await this.modalController.create({
      component: PremiumComponent,
      cssClass: 'for-settings',
    });
    this.modalController.dismiss();
    return await modal.present();
  }

  onClose(){
    this.modalController.dismiss();
  }
}
