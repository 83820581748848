import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthService } from '../auth.service';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit{
  @Input() info: string;
  ionicForm: FormGroup;
  loginError: [boolean, string] = [false, ''];
  signup = false;
  signupError: [boolean, string] = [false, ''];
  reset = false;
  resetError: [boolean, string] = [false, ''];
  app = true;

  validationMessages = {
    password: [
      { type: 'required', message: 'Field is required.' }
    ],
    email: [
      { type: 'required', message: 'Field is required.' },
      { type: 'email', message: 'Must be a valid email.' }
    ],
    confirm: [
      { type: 'required', message: 'Field is required.' },
      { type: 'passwordValidator', message: 'Passwords must match.' }
    ],
  };

  constructor(
    public modalController: ModalController,
    private auth: AuthService,
    public toastController: ToastController,
    private storage: StorageService,
    ){}


  ngOnInit(): void {
    this.app = this.storage.app;
    this.toLogIn();
  }

  submitForm(){
    const email = this.ionicForm.value.email;
    if(!this.reset){
      const password = this.ionicForm.value.password;

      if(!this.signup){
        this.auth.logIn(email, password).then(x => {

          this.loginError = [false, ''];
          this.modalController.dismiss();
          this.presentToast('Good Job!','You have been Logged In.');
        }).catch(
          err => {
            this.loginError = [true, this.getErrorMessage(err.message)];
            this.ionicForm.get('password').reset();
            this.ionicForm.markAsPristine();
          }
        );
      }else{
        this.auth.signUp(email, password).then(x => {
          console.log('after signup: ', x);

          this.signupError = [false, ''];
          this.modalController.dismiss();
          this.presentToast('Good Job!','You have been Logged In.');
        }).catch(
          err => {
            this.signupError = [true, this.getErrorMessage(err.message)];
            this.ionicForm.get('password').reset();
            this.ionicForm.get('confirm').reset();
            this.ionicForm.markAsPristine();
          }
        );
      }
    }else{
      this.auth.resetPassword(email).then(x => {
        console.log('email sent: ', x );
        this.presentToast('Email was sent!','Check your mailbox.');
        this.toLogIn();
      }).catch( err => {
        this.resetError = [true, this.getErrorMessage(err.message)];
        this.ionicForm.markAsPristine();
      });
    }
  }

  getErrorMessage(error: string): string{
    const pos1 = error.indexOf(':') + 1;
    const pos2 = error.lastIndexOf('(');
    return error.substring(pos1,pos2);
  }

  passwordValidator(fc: FormControl){
    if(fc && this.ionicForm.get('password')){
      const pass = this.ionicForm.get('password').value;
      if(fc.value !== pass){
        return ({passwordValidator: true});
      }else{
        return (null);
      }
    }else{
      return null;
    }
  }

  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  toLogIn(){
    this.signup = false;
    this.reset = false;
    this.signupError = this.resetError = this.loginError = [false, ''];
    this.ionicForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      password: new FormControl('', Validators.required),
    });
  }

  toSignUp(){
    if(this.app){
      this.signup = true;
      this.reset = false;
      this.signupError = this.resetError = this.loginError = [false, ''];
      this.ionicForm = new FormGroup({
        email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        password: new FormControl('', Validators.required),
        confirm: new FormControl('', Validators.compose([Validators.required, this.passwordValidator.bind(this)])),
      });
    }
  }

  toReset(){
    this.reset = true;
    this.signup = false;
    this.signupError = this.resetError = this.loginError = [false, ''];
    this.ionicForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
  }

  onClose(){
    this.modalController.dismiss();
  }
}
