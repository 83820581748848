import { Injectable } from '@angular/core';
import { getDatabase, set, ref, remove, get, child } from 'firebase/database';

@Injectable({
  providedIn: 'root'
})
export class DbService{

  constructor(){}

  getDb(user, path): Promise<any>{
    const dbFire = ref(getDatabase());
    console.log('path: ', user + path);
    return get(child(dbFire, 'Users/' + user + path)).then(snapshot => {
      if (snapshot.exists()) {
        console.log('snapshot val: ', snapshot.val());
        return snapshot.val();
      }else{
        console.log('no data: ', user,path);
        return ('nothing');
      }
    }).catch(err => console.log(err));
  }

  writeToDb(user, content): Promise<void>{
    const dbFire = getDatabase();
    return set(ref(dbFire, user ), content);
  }

  deleteFromDb(user){
    const dbFire = getDatabase();
    return remove(ref(dbFire, user));
  }

}
