import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../storage.service';
import { BasicEventModel, EventModel } from 'src/app/home/events/event/event.model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ModalController, ToastController } from '@ionic/angular';
import { PremiumComponent } from '../premium/premium.component';


@Component({
  selector: 'app-new-form',
  templateUrl: 'new-event.component.html',
  styleUrls: ['new-event.component.scss'],
})
export class NewFormComponent implements OnInit, OnDestroy{
  @Input() event: BasicEventModel;
  @Output() onFormConfirm = new EventEmitter<any>();
  @Output() formChangeOut = new EventEmitter<boolean>();
  @Output() statusChangeOut = new EventEmitter<any>();
  extendedEvent: EventModel;
  ionicForm: FormGroup;
  localeFormat = 'DD.MM.YYYY';
  timeFormat: any[] = [24, 'HH:mm'];
  defaultTime = '23:59';
  timer: any;
  timerRunning: boolean;
  futureTimestamp: number;
  changingValuesExpiration: boolean;
  changingValuesDate: boolean;
  formReset: boolean;
  editMode: boolean; // true when editing an event
  currentTimestamp: any;
  currentTime: any;
  formValid = true;
  formChange = false;
  subscription: Subscription;
  notificationId: number;
  formPupulated: boolean; // functions are available after form is populated
  canCreateEvents = true;
  activeEventsSubscribtion: Subscription;

  validationMessages = {
    name: [
      { type: 'required', message: 'Username is required.' }
    ],
    expiration: [
      { type: 'required', message: 'Enter time to Expiration or select a date below.' },
      { type: 'pattern', message: 'Must be more than 0.' }
    ],
    expirationDate: [
      { type: 'required', message: 'Enter a Date in format ' + this.localeFormat }, // Format from settings
      { type: 'customDateValidator', message: 'Date must be in future' }
    ],
  };


  constructor(
    private storage: StorageService,
    public toastController: ToastController,
    public modalController: ModalController
  ){
    if(this.storage.settings.dateFormat === '2'){
      this.localeFormat = 'MM.DD.YYYY';
    }
    if(this.storage.settings.timeFormat === '2'){
      this.timeFormat = [12, 'hh:mm a'];
      this.defaultTime = '11:59 pm';
    }
    this.editMode = false;
  }


  ngOnInit() {
    this.currentTimestamp = moment().toDate();
    this.currentTime = '00:00';
    this.timerRunning = false;
    this.validationMessages.expirationDate[0].message = 'Enter a Date in format ' + this.localeFormat;
    this.formPupulated = false;
    console.log('init new-event');
    console.log('1 ', this.storage.settings.user[0]);
    console.log('2 ', this.storage.events.value.length);
    console.log(this.storage.activeEvents);
    this.activeEventsSubscribtion = this.storage.activeEvents.subscribe(evNumber => {
      if(this.storage.settings.user[0] === 0 && evNumber >= 3){
        this.canCreateEvents = false;
      }
    });


    this.ionicForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      expiration: new FormControl(null, Validators.compose([Validators.required, Validators.pattern('[0-9]*')])),
      expirationTimeframe: new FormControl('days'),
      expirationDate: new FormControl('', Validators.compose([Validators.required, this.cutomDateValidator])),
      expirationTime: new FormControl(this.defaultTime, Validators.required)
    });

    if(this.event){
      this.editMode = true;

      this.storage.get(this.event.timestamp).then(extEvent => {
        this.extendedEvent = extEvent;

        this.notificationId = this.extendedEvent.notificationId;

        if(this.event.status === 'done' || this.event.status === 'expired'){
          this.futureTimestamp = moment(this.futureTimestamp).set({h: 23, m: 59}).valueOf();
        }else{
          this.futureTimestamp = this.event.expirationTimestamp;
        }

        this.ionicForm.setValue({
          name: this.event.name,
          description: this.extendedEvent.description,
          expiration: null,
          expirationTimeframe: 'days',
          expirationDate: moment(this.futureTimestamp).toDate(),
          expirationTime: moment(this.futureTimestamp).format(this.timeFormat[1])
        });

        this.calculateExpirationValue();

        this.changingValuesExpiration = true;
        this.formReset = false;
        this.changingValuesDate = true;
        this.formPupulated = true;

        this.subscription = this.ionicForm.valueChanges.subscribe(() => {
          this.formChange = true;
          this.formChangeOut.emit(true);
        });

      }).catch(e => console.log('error: ', e));

    }else{
      // when in new event
      this.calculateNotificationId();

      this.changingValuesExpiration = true;
      this.formReset = false;
      this.changingValuesDate = true;
      this.formPupulated = true;

      this.subscription = this.ionicForm.valueChanges.subscribe(() => {
        this.formChange = true;
        this.formChangeOut.emit(true);
      });
    }
  }

  async toPremium(){
    const modal = await this.modalController.create({
      component: PremiumComponent,
      cssClass: 'for-settings',
    });
    return await modal.present();
  }


  onInput(event: any){
    if(this.formPupulated){
      if(!this.formReset){
        this.timerRunning = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.calculateDate();
          if(this.changingValuesDate){
            this.changingValuesExpiration = false;
            this.ionicForm.controls.expirationDate.setValue(moment(this.futureTimestamp).toDate());
          }
          this.changingValuesDate = true;
          this.timerRunning = false;
        },1000);
      }
      if(!this.formReset && event === null && this.futureTimestamp){
        let timeout = 1000;
        if(event === null){
          timeout = 4000;
        }
        this.timerRunning = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.calculateExpirationValue();
          this.timerRunning = false;
        },timeout);
      }
    }
  }

  cutomDateValidator(fc: FormControl){
    const fcValue = moment(fc.value).set({h: 23, m: 59}).valueOf();
    const currentTimestamp = moment().valueOf();
    if(fc.value && fcValue < currentTimestamp){
      return ({customDateValidator: true});
    }else{
      return (null);
    }
  }

  calculateDate(){
    let expirationValue = 1;
    if(this.ionicForm.value.expiration > 1){
      expirationValue = this.ionicForm.value.expiration;
    }

    const expirationTimeframe = this.ionicForm.value.expirationTimeframe;

    const expirationTime = this.timeFormat[0] === 12 ?
        moment(this.ionicForm.value.expirationTime, 'hh:mm a').format('HH:mm').split(':')
      :
        this.ionicForm.value.expirationTime.split(':');

    const futureTimestamp = moment().set({h: expirationTime[0], m: expirationTime[1], s: 59}); // set time of futureTimestamp

    const timeDiff = moment().set({h: expirationTime[0], m: expirationTime[1], s: 59}).diff(moment());
    switch(expirationTimeframe){
      case 'days': {
        futureTimestamp.add(expirationValue,'days');
        break;
      }
      case 'months': {
        futureTimestamp.add(expirationValue,'months');
        break;
      }
      case 'years': {
        futureTimestamp.add(expirationValue,'years');
        break;
      }
    }
    if(timeDiff < 0){
      futureTimestamp.add(1, 'day');
    }

    this.futureTimestamp = futureTimestamp.valueOf();
  }

  calculateExpirationValue(){
    const expirationTimeframe = this.ionicForm.value.expirationTimeframe;
    const currentTimestamp = moment();
    const futureTimestamp = moment(this.futureTimestamp);
    this.defaultTime = moment(this.futureTimestamp).format(this.timeFormat[1]);
    let dayDiff = 0;
    let monthDiff = 0;

    switch(expirationTimeframe){
      case 'days': {
        dayDiff = futureTimestamp.diff(currentTimestamp, 'days');
        this.changingValuesDate = false;
        this.ionicForm.controls.expiration.setValue(dayDiff);
        break;
      }
      case 'months': {
        monthDiff = futureTimestamp.diff(currentTimestamp, 'months');
        this.changingValuesDate = false;
        if(monthDiff < 1){
          dayDiff = futureTimestamp.diff(currentTimestamp, 'days');
          this.ionicForm.controls.expirationTimeframe.setValue('days');
          this.ionicForm.controls.expiration.setValue(dayDiff);
        }else{
          this.ionicForm.controls.expiration.setValue(monthDiff);
        }
        break;
      }
      case 'years': {
        const yearDiff = futureTimestamp.diff(currentTimestamp, 'years');
        this.changingValuesDate = false;
        if(yearDiff < 1){
          monthDiff = futureTimestamp.diff(currentTimestamp, 'months');
          if(monthDiff < 1){
            dayDiff = futureTimestamp.diff(currentTimestamp, 'days');
            this.ionicForm.controls.expirationTimeframe.setValue('days');
            this.ionicForm.controls.expiration.setValue(dayDiff);
          }else{
            this.ionicForm.controls.expirationTimeframe.setValue('months');
            this.ionicForm.controls.expiration.setValue(monthDiff);
          }
        }else{
          this.ionicForm.controls.expiration.setValue(yearDiff);
        }
        break;
      }
    }
  }

  onTimeChange(event: any){
    if(this.formPupulated){
      if(this.futureTimestamp && !this.formReset){
        const expirationTime = this.timeFormat[0] === 12 ?
            moment(this.ionicForm.value.expirationTime, 'hh:mm a').format('HH:mm').split(':')
          :
            this.ionicForm.value.expirationTime.split(':');

        const futureTimestamp = moment(this.futureTimestamp);

        futureTimestamp.set({h: expirationTime[0], m: expirationTime[1]}); // set time of futureTimestamp

        this.futureTimestamp = futureTimestamp.valueOf();

        this.checkDateTime();
        this.calculateExpirationValue();
      };
    }
  }

  onDateChange(event: Date){
    if(this.formPupulated){
      if(!this.formReset && this.changingValuesExpiration){

        const expirationTime = this.ionicForm.value.expirationTime.split(':');

        const futureTimestamp = moment(event);
        futureTimestamp.set({h: expirationTime[0], m: expirationTime[1]}); // set time of futureTimestamp

        this.futureTimestamp = futureTimestamp.valueOf();

        if(moment(this.futureTimestamp).format('MM/DD/YYYY') === moment(this.currentTimestamp).format('MM/DD/YYYY')){
          this.currentTime = moment().format('HH:mm');
        }else{
          this.currentTime = '00:00';
        }

        this.checkDateTime();
        this.calculateExpirationValue();
      }
      this.changingValuesExpiration = true;
    }
  }

  // check if date and time is in future
  checkDateTime(){
    if(this.futureTimestamp <= this.currentTimestamp){
      console.log('timestamp not in future');
      this.formValid = false;
    }else{
      console.log('event in future');
      this.formValid = true;
    }
  }

  onDateFocus(event: FocusEvent){
    this.targetElement(event,'focus');
  }

  onDateBlur(event: FocusEvent){
    this.targetElement(event,'blur');
  }

  targetElement(event: FocusEvent, action: string){
    const target = event.composedPath().filter(x => {
      const element = (x as HTMLElement).nodeName;
      if(element){
        return element.toLowerCase() === 'ion-item';
      }
    });
    if(action === 'focus'){
      (target[0] as HTMLElement).classList.add('item-has-focus');
    }else{
      (target[0] as HTMLElement).classList.remove('item-has-focus');
    }

  }

  // mark as active and return previous values
  onStatusChange(){
    let event: EventModel;
    this.ionicForm.disable();
    this.statusChangeOut.emit();
    this.storage.get(this.event.timestamp)
      .then(eventRet => event = eventRet).catch(e => console.log('error: ', e))
      .then(() => {
        event.status = 'active';
        event.markedAsDone = 0;
        event.editTimestamp = moment().valueOf();
        event.synced = false;
        event.notifications = [];
        this.storage.set(this.event.timestamp, event).then(() =>
          this.presentToast('Event set as ' + event.status.toUpperCase(), 'Event: ' + event.name )
        ).catch(e => this.presentToast('Event ' + e, 'Try again'));
      }).catch(e => console.log('error: ', e));
  }

  calculateNotificationId(){
    this.notificationId = this.storage.getNotificationId();
  }

  submitForm(){
    let currentTimestamp: string;
    let eventStatus: string;
    let markedAsDone = 0;
    let message: string;

    if(this.editMode){
      console.log('edit mode event: ',this.event);
      currentTimestamp = this.event.timestamp;

      message = 'UPDATED';
      // editing Expired or Done event and changing expirationTimestamp
      if(this.event.expirationTimestamp < this.futureTimestamp && this.event.status !== 'active'){
        eventStatus = 'active';
        console.log('not active');
      // editing any event without changing expirationTimestamp
      }else{
        eventStatus = this.event.status;
        if(this.event.markedAsDone){
          markedAsDone = this.event.markedAsDone;
        }
        console.log('is active');
      }
    }else{
      currentTimestamp = moment().valueOf().toString();
      message = 'CREATED';
      eventStatus = 'active';
    }

    const event: EventModel = {
      open: false,
      name: this.ionicForm.value.name,
      timestamp: currentTimestamp,
      status: eventStatus,
      markedAsDone,
      expirationTimestamp: this.futureTimestamp,
      description: this.ionicForm.value.description,
      editTimestamp: moment().valueOf(),
      notificationId: this.notificationId,
      synced: false,
      notifications: 'waiting',
    };

    console.log('marked as done: ', markedAsDone);
    this.ionicForm.disable();
    this.storage.set(currentTimestamp, event).then(() => {
      this.presentToast('Event successfully ' + message, 'Good Job!');
    }).catch(e => this.presentToast('Event ' + e, 'Try again'));

    if(!this.editMode){
      this.formReset = true;

      this.ionicForm.reset({
        expirationTimeframe: 'days',
        expirationTime: this.defaultTime,
        expiration: null
      });

      this.formReset = false;
      this.formChangeOut.emit(false);
      this.onFormConfirm.emit();
    }
  }

  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
      this.activeEventsSubscribtion.unsubscribe();
  }
}
