import { Component, OnInit } from '@angular/core';
import { StorageService } from '../shared/storage.service';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit{
  user = 'offline';
  app = true;

  constructor(
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
      this.storage.network.subscribe(x => this.user = x[1]);
      this.app = this.storage.app;
  }
}
