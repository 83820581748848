import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header.component';
import { MenuComponent } from '../menu/menu.component';
import { SetFiltersComponent } from '../filters/setfilters.component';


@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    SetFiltersComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [ HeaderComponent]
})
export class HeaderModule{

}
