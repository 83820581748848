/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Subscription, take } from 'rxjs';
import { BasicEventModel, EventModel, EventNotifications} from '../home/events/event/event.model';
import { SettingsModel } from './settings/settings.model';
import { LocalNotifications, PendingResult } from '@capacitor/local-notifications';
import * as moment from 'moment';
import { AuthService } from './auth.service';
import { DbService } from './db.service';
import { ConnectionStatus, Network } from '@capacitor/network';
import { UserModel } from './auth/user.model';
import { AlertController, Platform, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class StorageService {
  events = new BehaviorSubject<BasicEventModel[]>([]);
  temporaryEvents: BasicEventModel[] = [];
  initEventsLoaded = false;
  filterEventsTimer: any;
  saving = new BehaviorSubject<boolean>(false);
  loadingStorage = new BehaviorSubject<boolean>(true);
  loadingSettings = new BehaviorSubject<boolean>(true);
  settings: SettingsModel;
  filters: boolean[];
  eventsToLoad: number;
  user = new BehaviorSubject<UserModel>({name: '', role: 'owner'});
  isLinkedAccount: boolean;
  network = new BehaviorSubject<string[]>(['offline', 'offline']); // ['network status', 'user status']
  loggingIn = true;
  app = true;
  notificationIds: number[] = [];
  productsRegistered = false;
  initLogin = true;
  activeEvents = new BehaviorSubject<number>(0);
  syncInProgress = new BehaviorSubject<boolean>(false);
  initSync = new BehaviorSubject<boolean>(false);


  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    private auth: AuthService,
    private db: DbService,
    public platform: Platform,
    public toastController: ToastController,
    public alertController: AlertController,
    ) {

    this.checkPlatform();
    this.init();
  }

  checkPlatform(){
    const platform = this.platform.platforms();
    if(platform.includes('android') && !platform.includes('mobileweb')){
      this.app = true;
    }else{
      this.app = false;
    }
  }

  async init(){
    // clean start
    this.cleanStart();

    // create storage
    if(this.app){
      await this.storage.create().then(x => {
        this._storage = x;
        this.loadingStorage.next(false);
      });
    }else{
      this.initSync.next(true);
    }

    // connection status
    this.connectionStatus().then(() => {

      // login status
      this.loginStatus().then(() => {

        // app start
        this.appStart();
      });

    });

    Network.addListener('networkStatusChange', this.connectionStatus.bind(this));
  }

  cleanStart(){
    this.events.next([]);
    this.temporaryEvents = [];
    this.defaultSettings();
    this.initEventsLoaded = false;
    this.loadingSettings.next(true);
  }

  connectionStatus(): Promise<string>{
    return this.getNetworkStatus().then(status => {
      if((status.connectionType === 'wifi' && status.connected) ||
        (status.connectionType !== 'wifi' && this.settings.network === '2' && status.connected) ||
        status.connected && !this.app){
        // online
        this.network.next(['online', this.network.value[1]]);
        return 'online';
      }else{
        // offline
        this.network.next(['offline', this.network.value[1]]);
        return 'offline';
      }
    });
  }

  async appStart(){
    if(this.app){
      this.get('settings').then(settings => {
        if(settings){
          this.network.subscribe(networkRes => {
            if(networkRes[1] === 'offline'){
              this.updateSettings(Object.assign(this.settings, settings));
              this.loadingSettings.next(false);
              this.initSync.next(true);
              console.log('getting events');
              this.getEvents();
            }else{ // if network online
              console.log('sync app - online + loading settings: ', this.loadingSettings.value);
              this.syncInProgress.next(true);
              this.getEvents();
              this.syncApp();
            }
          });
        }else{ // no settings
          console.log('no settings');
          this.loadingSettings.next(false);
          this.initSync.next(true);
          this.getEvents();
        }
      }).catch(e => this.presentAlert(e))
      .then(() => {
        this.user.next({name: this.settings.linkedAccount, role: 'owner'});
      }).catch(e => this.presentAlert(e));

    }else{
      this.network.subscribe(x => {
        if(x[1] === 'online'){
          console.log('getting events2');
          this.get('settings').then(settings => {
            if(settings !== 'nothing'){
              this.updateSettings(Object.assign(this.settings, settings));
              console.log('this settings: ', this.settings);
              this.loadingSettings.next(false);
              console.log('getting events3', this.initEventsLoaded);
              console.log('network: ', this.network.value);
              this.getEvents();
            }else{ // no settings
              this.loadingSettings.next(false);
              this.getEvents();
            }
          }).catch(e => this.presentAlert(e));
        }else{
          console.log('x offline');
          this.initEventsLoaded = true;
          this.loadingSettings.next(false);
          this.events.next([]);
        }
      });
    }
  }

  loginStatus(): Promise<string>{
    const userName = this.user.value.name; // Default user - owner
    return new Promise((resolve) => {
      this.auth.userStatus.subscribe(x => {
        console.log('status change: ',x);
        if(!this.initLogin){
          this.loggingIn = true;
          let userSubscription: Subscription;
          if(x === 'loggedIn'){
            console.log('setting user1: ');
            userSubscription = this.auth.user.subscribe(user => {
              console.log('setting user2: ', user);
              if(user){
                console.log('setting user3: ', user);
                if((userName !== user) && this.app){
                  //first log in - set linked account
                  if(this.settings.linkedAccount === ''){
                    const newSettings = this.settings;
                    newSettings.linkedAccount = user;
                    this.setSettings('settings', newSettings);
                    this.user.next({name: user, role: 'owner'});
                    this.isLinkedAccount = true;
                  }else{
                    this.isLinkedAccount = false;
                    this.user.next({name: user, role: 'guest'});
                  }
                }else{
                  this.user.next({name: user, role: 'owner'});
                  this.isLinkedAccount = true;
                }
                userSubscription.unsubscribe();
                this.loggingIn = false;
              }
            });
            this.network.next([this.network.value[0],'online']);
            resolve('online');
          }else{ // if not loggedIn
            this.loggingIn = false;
            this.cleanStart();
            this.network.next([this.network.value[0],'offline']);
            if(userSubscription){
              userSubscription.unsubscribe();
            }
            console.log('logging out');
            this.isLinkedAccount = false;
            this.user.next({name: '', role: 'owner'});
            resolve('offline');
          }
        }else{
          this.initLogin = false;
        }
      });
      this.auth.canLogIn = true;
    });
  }


  async getNetworkStatus(): Promise<ConnectionStatus>{
    return Network.getStatus();
  }

  updateSettings(settings: SettingsModel){
    this.settings = {
      dateFormat: settings.dateFormat,
      timeFormat: settings.timeFormat,
      greenZone: settings.greenZone,
      orangeZone: settings.orangeZone,
      redZone: settings.redZone,
      notifications: settings.notifications,
      notificationTime: settings.notificationTime,
      filter: settings.filter,
      initLoad: settings.initLoad,
      nightMode: settings.nightMode,
      linkedAccount: settings.linkedAccount,
      network: settings.network,
      editTimestamp: settings.editTimestamp,
      user: settings.user,
    };
    this.filters = settings.filter;
    this.eventsToLoad = settings.initLoad;
  }

  public set(key: string, value: EventModel, origin?: string){
    this.saving.next(true);

    if(this.app){ // set to Local Storage in the app
      if(this.network.value[0] === 'online'){
        value.synced = true;
        return this.setToStorage(key, value, origin).then(() => {
          if(origin !== 'notifications') {
            this.filterEvents(key, value);
          }
          return this.setToDb(key,value, origin);
        });
      }else{
        return this.setToStorage(key, value, origin).then(() => {
          if(origin !== 'notifications') {
            this.filterEvents(key, value);
          }
        });
      }
    }else{ // set to DB outside the app
      if(key !== 'settings'){
        if(!this.notificationIds.find(id => id === value.notificationId)){
          this.notificationIds.push(value.notificationId);
        }
        return this.setToDb(key, value, origin).then(() => {
          if(origin !== 'notifications') {
            this.filterEvents(key, value);
          }
        });
      }
    }
  }


  public setSettings(key: string, value: SettingsModel): Promise<void>{
    if(this.app){ // set to Local Storage in the app
      return this._storage?.set(key, value).then(() => {

        this.updateSettings(value);
        console.log('setting settings after sync');
        this.getEvents();

        // update notifications
        this._storage.forEach((valueRes, keyRes) => {
          if(keyRes !== 'settings'){
            this.calculateNotifications(valueRes.notificationId, valueRes.name, valueRes.expirationTimestamp, keyRes);
          }
        });
      });
    }
    if(!this.app || this.network.value[1] === 'online'){ // set to DB
      return this.db.writeToDb('Users/' + this.auth.getUID().value + '/' + key, value).then(() => {

        this.updateSettings(value);
        this.getEvents();

        // update notifications - TODO UPDATE ONLY EVENT NOTIFICATIONS (NOT Local Notifications)
        return new Promise<any>((resolve, reject) => {
          this.auth.getUID().subscribe(x => {
            if(x !== ''){
              resolve(this.db.getDb(x, '/events')
                .then(events => {
                  if(events !== 'nothing'){
                    Object.entries(events).forEach(([keyRes, valueRes]) => {
                      const eventKey: string = keyRes;
                      const eventValue: EventModel = valueRes as EventModel;
                      this.calculateNotifications(eventValue.notificationId, eventValue.name, eventValue.expirationTimestamp, eventKey);
                    });
                  }
                }).catch(e => console.log(e))
              );
            }else{
              reject('waiting for auth');
            }
          });
        });
      }).catch(e => this.presentAlert(e));
    }
  }

  public get(key: string): Promise<any>{
    if(key === 'settings'){
      if(this.app && this.network.value[1] === 'offline'){ // get from Local Storage in the app
        if(this._storage){
          return this._storage.get(key);
        }else{
          return new Promise<any>((resolve, reject) => resolve(this.settings));
        }

      }else{
        return new Promise<any>((resolve, reject) => {

          let counter = 0;
          let uid = this.auth.getUID().value;
          const waitForUID = setInterval(() => {
            counter = counter + 1;
            uid = this.auth.getUID().value;
            if((counter >= 10) || (uid !== '')){
              clearInterval(waitForUID);
              if(uid !== ''){
                resolve(this.db.getDb(uid, '/' + key));
              }else{
                reject('waiting for auth');
              }
            }
          }, 100);
        });
      }
    }else{
      if(this.app){ // get from Local Storage in the app
        return this._storage.get(key);
      }else{ // get from DB
        return new Promise<any>((resolve, reject) => {

          let counter = 0;
          let uid = this.auth.getUID().value;
          const waitForUID = setInterval(() => {
            counter = counter + 1;
            uid = this.auth.getUID().value;
            if((counter >= 10) || (uid !== '')){
              clearInterval(waitForUID);
              if(uid !== ''){
                resolve(this.db.getDb(uid, '/events/' + key));
              }else{
                reject('waiting for auth');
              }
            }
          }, 100);
        });
      }
    }
  }

  public setFilters(filters: boolean[]){
    this.filters = filters;
  }

  public getEvents(): Promise<any>{
    const filteredEvents: string[] = [];
    this.notificationIds = [];
    this.activeEvents.next(0);
    if(this.app){ // get events from Local Storage in the app
      this.initEventsLoaded = true;
      return this._storage.forEach((value, key) => {
        if(key !== 'settings'){
          if(value.status === 'active'){
            this.activeEvents.next(this.activeEvents.value + 1);
          }
          filteredEvents.push(value.timestamp);
          this.filterEvents(key, value);
          if(value.notificationId){
            this.notificationIds.push(value.notificationId);
          }
        }
      }).then(() => {
        if(this.notificationIds.length === 0){
          this.events.next([]);
        }else{
          this.findDeletedEvent(filteredEvents);
        }
      });
    }else{ // get from DB
      return new Promise<any>((resolve, reject) => {

        let counter = 0;
        let uid = this.auth.getUID().value;
        const waitForUID = setInterval(() => {
          counter = counter + 1;
          uid = this.auth.getUID().value;
          if((counter >= 10) || (uid !== '')){
            clearInterval(waitForUID);
            if(uid !== ''){
              resolve(this.db.getDb(uid, '/events')
              .then(events => {
                this.initEventsLoaded = true;
                if(events !== 'nothing'){
                  Object.entries(events).forEach(([key, value]) => {
                    const eventKey: string = key;
                    const eventValue: EventModel = value as EventModel;
                    if(eventValue.status === 'active'){
                      this.activeEvents.next(this.activeEvents.value + 1);
                    }
                    filteredEvents.push(eventValue.timestamp);
                    this.filterEvents(eventKey, eventValue);
                    if(eventValue.notificationId){
                      this.notificationIds.push(eventValue.notificationId);
                    }
                  });
                }else{
                  this.events.next([]);
                }
              }).catch(e => this.presentAlert(e)).then(() => {
                this.findDeletedEvent(filteredEvents);
              })
              );
            }else{
              reject('waiting for auth');
            }
          }
        }, 100);
      });
    }
  }

  removeUser(){
    const uid = this.auth.getUID().value;

    this.auth.presentAlertPrompt('Are you sure?', 'You really want to DELETE current USER?', 'Enter your password:')
      .then(x => console.log('ALERT RESULT: ',x));

    this.auth.removingUser.subscribe(x => {
      if(x === '0'){
        console.log('starting removing');
      }else if(x === '1'){
        console.log('can remove');

        // remove storage
        if(this._storage){
          this._storage.clear();
        }

        // remove notifications
        this.getPendingNotifications().then(notifications => {
          if(notifications.notifications.length > 0){
            notifications.notifications.forEach(notif => {
              this.cancelNotifications(notif.id);
            });
          }
        });

        // remove DB
        this.db.deleteFromDb('Notifications/' + uid).catch(e => this.presentAlert(e));
        this.db.deleteFromDb('Notifications/Selection/' + uid).catch(e => this.presentAlert(e));
        this.db.deleteFromDb('Users/' + uid).then(() => this.auth.removingUser.next('2')).catch(e => this.presentAlert(e));
      }
    });
  }

  findDeletedEvent(eventsDB: string[]){
    const included = this.events.value.filter(x => !eventsDB.includes(x.timestamp));
    included.forEach(value => this.removeFromEvents(value.timestamp));
  }

  filterEvents(key: string, value: EventModel){
    if(value.status === 'active' && this.filters[0] ||
      value.status === 'expired' && this.filters[1] ||
      value.status === 'done' && this.filters[2]){
        const event: BasicEventModel = {
          name: value.name,
          timestamp: key,
          status: value.status,
          expirationTimestamp: value.expirationTimestamp,
          notificationId: value.notificationId,
          synced: value.synced,
          markedAsDone: value.markedAsDone
        };
        this.addEventToEvents(event);
    }else{
      this.removeFromEvents(value.timestamp);
    }
  }


  syncApp(){
    // Compare Local Storage to DB
    this.compareLocalWithDB().then(x => {
      if(x === 'done'){
        // Compare DB to Local Storage
        this.compareDBWithLocal().then(() => {
          console.log('app synced');
          this.syncInProgress.next(false);
          this.initSync.next(true);
          this.presentToast('SYNCED!', 'Application is now Synchronized');
        });
      }
    });
  }

  compareLocalWithDB(): Promise<any>{
    const localUnsyncedEvents: EventModel[] = [];
    return this._storage.forEach((value, key) => {
      if(key !== 'settings'){
        if(!value.synced){
          localUnsyncedEvents.push(value);
        }
      }
    }).catch(e => this.presentAlert(e)).then(() => new Promise<any>((resolve, reject) => {
        let uid = this.auth.getUID().value;
        const totalEvents = localUnsyncedEvents.length;

        let waiting = 0;

        const waitForUID = setInterval(() => {
          if(waiting > 20){
            clearInterval(waitForUID);
            this.presentAlert('There seems to be a problem with connection');
          }
          waiting = waiting + 1;
          if(uid === ''){
            uid = this.auth.getUID().value;
          }else{

            clearInterval(waitForUID);
            let counter = 0;
            let eventIndex = 0;
            let next = true;
            let settingsSynced = false;
            const compareEvents = setInterval(() => {

              //sync settings first
              if(!settingsSynced){
                this.db.getDb(uid, '/settings').catch(e => this.presentAlert(e)).then(settingsDB => {
                  if(settingsDB){
                    if(settingsDB.editTimestamp > this.settings.editTimestamp){
                      console.log('first settings sync');
                      this._storage.set('settings', settingsDB);
                      this.updateSettings(settingsDB);
                    }else if(settingsDB.editTimestamp < this.settings.editTimestamp){
                      console.log('second settings sync');
                      this.db.writeToDb('Users/' + uid + '/settings', this.settings);
                    }
                  }else{
                    console.log('third settings sync');
                    this.db.writeToDb('Users/' + uid + '/settings', this.settings);
                  }
                  settingsSynced = true;
                  this.loadingSettings.next(false);
                });
              }

              if(totalEvents === 0){
                clearInterval(compareEvents);
                return resolve('done');
              }

              if(counter > 20 || next){

                next = false;

                this.db.getDb(uid, '/events/' + localUnsyncedEvents[eventIndex].timestamp).catch(e => this.presentAlert(e))
                .then(eventDB => {
                  let newEvent: EventModel;
                  if(eventDB !== 'nothing'){
                    if(localUnsyncedEvents[eventIndex].editTimestamp > eventDB.editTimestamp){
                      newEvent = localUnsyncedEvents[eventIndex];
                    }else{
                      newEvent = eventDB;
                    }
                  }else{
                    newEvent = localUnsyncedEvents[eventIndex];
                  }
                  newEvent.synced = true;

                  // set event in Local
                  this.setToStorage(newEvent.timestamp, newEvent).catch(e => this.presentAlert(e)).then(() => {
                    this.filterEvents(newEvent.timestamp, newEvent);
                    // set event in DB
                    resolve(this.setToDb(newEvent.timestamp, newEvent).then(() => {
                      // remove deleted events
                      if(newEvent.status === 'deleted'){

                        this._storage.get(newEvent.timestamp).then(xEvent => {
                          const notificationId = xEvent.notificationId;
                          this._storage.remove(newEvent.timestamp).then(() => {
                            this.cancelNotifications(notificationId);
                            this.events.next(this.events.getValue().filter(x => x.timestamp !== newEvent.timestamp));
                          });
                        });

                        // remove event from DB
                        this.db.deleteFromDb('Users/' + uid + '/events/' + newEvent.timestamp).catch(e => this.presentAlert(e));

                      }
                    }));
                  }).catch(e => this.presentAlert(e)).then(() => {
                    next = true;
                    eventIndex = eventIndex + 1;
                  });
                });
              }
              counter = counter + 1;
              if(eventIndex === totalEvents - 1){
                clearInterval(compareEvents);
                resolve('done');
              }
            }, 100);
          }
        }, 100);
      }));
  }

  compareDBWithLocal(): Promise<any>{
    const dbUnsyncedEvents: EventModel[] = [];
    const uid = this.auth.getUID().value;

    return this.db.getDb(uid, '/events')
      .then(events => {
        Object.entries(events).forEach(([key, value]) => {
          const eventKey: string = key;
          const eventValue: EventModel = value as EventModel;
          if(!eventValue.synced){
            dbUnsyncedEvents.push(eventValue);
          }
        });
      }).catch(e => this.presentAlert(e)).then(() => new Promise<any>((resolve, reject) => {
          const totalEvents = dbUnsyncedEvents.length;

          if(!(totalEvents > 0)){
            return resolve('done');
          }
          let counter = 0;
          let eventIndex = 0;
          let next = true;
          const compareEvents = setInterval(() => {
            if(counter > 30 || next){
              next = false;
              this._storage.get(dbUnsyncedEvents[eventIndex].timestamp).catch(e => this.presentAlert(e))
              .then(eventStorage => {
                let newEvent: EventModel;
                if(eventStorage){
                  if(dbUnsyncedEvents[eventIndex].editTimestamp > eventStorage.editTimestamp){
                    newEvent = dbUnsyncedEvents[eventIndex];
                  }else{
                    newEvent = eventStorage;
                  }
                }else{
                  newEvent = dbUnsyncedEvents[eventIndex];
                }
                newEvent.synced = true;
                // set event in Local
                this.setToStorage(newEvent.timestamp, newEvent).catch(e => this.presentAlert(e)).then(() => {
                  this.filterEvents(newEvent.timestamp, newEvent);
                  // set event in DB
                  resolve(this.setToDb(newEvent.timestamp, newEvent).then(() => {
                    // remove deleted events
                    if(newEvent.status === 'deleted'){

                      this._storage.get(newEvent.timestamp).then(xEvent => {
                        const notificationId = xEvent.notificationId;
                        this._storage.remove(newEvent.timestamp).then(() => {
                          this.cancelNotifications(notificationId);
                          this.events.next(this.events.getValue().filter(x => x.timestamp !== newEvent.timestamp));
                        });
                      });

                      // remove event from DB
                      this.db.deleteFromDb('Users/' + uid + '/events/' + newEvent.timestamp).catch(e => this.presentAlert(e));
                    }
                  }));
                }).catch(e => this.presentAlert(e)).then(() => {
                  console.log('event set to DB');
                  eventIndex = eventIndex + 1;
                  if(eventIndex === totalEvents){
                    next = false;
                  }else{
                    next = true;
                  }
                });
              });
            }
            counter = counter + 1;
            if(eventIndex === totalEvents){
              clearInterval(compareEvents);
              return resolve('done');
            }
          }, 100);
        }));
  }

  //NOTIFICATIONS

  public getNotificationId(): number{
    let numberInArray = true;
    let newNotificationId = 100;
    if(!this.app){
      newNotificationId = 300;
    }
    while(numberInArray){
      const found = this.notificationIds.find(id => id === newNotificationId);
      if(found){
        newNotificationId = newNotificationId + 1;
        continue;
      }else{
        numberInArray = false;
      }
    }
    return newNotificationId;
  }

  getPendingNotifications(): Promise<PendingResult>{
    return LocalNotifications.getPending();
  }

  cancelNotifications(notificationId: number): Promise<void>{

    if(this.network.value[1] === 'online'){
      // Cancel from DB Notifications
      this.db.deleteFromDb('Notifications/' + this.auth.getUID().value + '/' + notificationId.toString());
    }

    return this.getPendingNotifications().then(notifications => {

      if(notifications.notifications.length > 0){
        const eventNotifications = notifications.notifications.filter(x => x.id.toString().substring(0,3) === notificationId.toString());
        const idsToCancel = [];
        eventNotifications.forEach(x => idsToCancel.push({id: x.id}));
        if(eventNotifications.length > 0){
          return LocalNotifications.cancel(
            {notifications: idsToCancel}
          );
        }
      }
    });
  }

  calculateNotifications(notificationId: number, eventName: string, eventExpiration: number, eventId: string): Promise<void>{

    // calculate notifications - put them in array and at the end attach this array to event (eventId)

    const inNotificationId: number = notificationId;
    // 1. If event already has notifications, cancel them and set new ones
    return this.cancelNotifications(notificationId).then(() =>
      {

        const notificationTime = this.settings.notificationTime.split(':');
        const greenZoneDateTime = moment(eventExpiration).subtract(this.settings.greenZone,'days')
        .set({h: +notificationTime[0], m: +notificationTime[1]});
        const orangeZoneDateTime = moment(eventExpiration).subtract(this.settings.orangeZone,'days')
        .set({h: +notificationTime[0], m: +notificationTime[1]});
        const redZoneDateTime = moment(eventExpiration).subtract(this.settings.redZone,'days')
        .set({h: +notificationTime[0], m: +notificationTime[1]});

        const notificationIdString: string = inNotificationId.toString();
        const eventNotifications: EventNotifications[] = [];

        let notificationIdIn = 0;
        let i = 1;
        let daysLeft = moment();

        if(greenZoneDateTime > moment()){
          notificationIdIn = 0;
          i = 1;
          daysLeft = redZoneDateTime;

          // Notification for reaching green zone
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(greenZoneDateTime.toDate(), notificationIdIn, eventName, this.settings.greenZone));
          i++;

          // Notification for reaching orange zone
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(orangeZoneDateTime.toDate(), notificationIdIn, eventName, this.settings.orangeZone));
          i++;
          // Notification for every day in red zone
          for (i; i < (this.settings.redZone + 3); i++){
            notificationIdIn = +(notificationIdString + i.toString());
            eventNotifications.push(this.addNotification(daysLeft.toDate(), notificationIdIn, eventName, this.settings.redZone - (i - 3)));
            daysLeft = daysLeft.add(1, 'day');
          }
          // Notification for expired event
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(moment(eventExpiration).toDate(), notificationIdIn, eventName, 0));

        }else if((greenZoneDateTime <= moment()) && (moment() < orangeZoneDateTime)){
          notificationIdIn = 0;
          i = 1;
          daysLeft = redZoneDateTime;

          // Notification for reaching orange zone
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(orangeZoneDateTime.toDate(), notificationIdIn, eventName, this.settings.orangeZone));
          i++;
          // Notification for every day in red zone
          for (i; i < (this.settings.redZone + 2); i++){
            notificationIdIn = +(notificationIdString + i.toString());
            eventNotifications.push(this.addNotification(daysLeft.toDate(), notificationIdIn, eventName, this.settings.redZone - (i - 2)));
            daysLeft = daysLeft.add(1, 'day');
          }
          // Notification for expired event
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(moment(eventExpiration).toDate(), notificationIdIn, eventName, 0));

        }else if((orangeZoneDateTime <= moment()) && (moment() < redZoneDateTime)){
          notificationIdIn = 0;
          i = 1;
          daysLeft = redZoneDateTime;

          // Notification for every day in red zone
          for (i; i < (this.settings.redZone + 1); i++){
            notificationIdIn = +(notificationIdString + i.toString());
            eventNotifications.push(this.addNotification(daysLeft.toDate(), notificationIdIn, eventName, this.settings.redZone - (i - 1)));
            daysLeft = daysLeft.add(1, 'day');
          }
          // Notification for expired event
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(moment(eventExpiration).toDate(), notificationIdIn, eventName, 0));

        }else{
          notificationIdIn = 0;
          i = 1;
          daysLeft = moment().set({h: +notificationTime[0], m: +notificationTime[1]}); // notification time of current day
          const countDown = moment(eventExpiration).diff(daysLeft, 'days');

          // Notification for every remaining day in red zone
          for (i; i < (countDown + 1); i++){
            notificationIdIn = +(notificationIdString + i.toString());
            if(daysLeft.toDate() > moment().toDate()){
              eventNotifications.push(this.addNotification(daysLeft.toDate(), notificationIdIn, eventName, countDown - (i - 1)));
            }
            daysLeft = daysLeft.add(1, 'day');
          }
          // Notification for expired event
          notificationIdIn = +(notificationIdString + i.toString());
          eventNotifications.push(this.addNotification(moment(eventExpiration).toDate(), notificationIdIn, eventName, 0));
        }

        this.get(eventId).then(event => {
          event.notifications = eventNotifications;
          this.set(eventId, event, 'notifications');
        });

        if(this.network.value[1] === 'online'){
          // Set to DB Notifications
          eventNotifications.forEach(notification => {
            console.log('setting notifications to db: ', notification);
            this.db.writeToDb(
              'Notifications/' + this.auth.getUID().value + '/' + notificationIdString + '/' +  notification.notificationID, notification
            );
          });
        }
      }
    );
  }

  addNotification(notificationDate: Date, notificationId: number, notificationBody: string, daysToExpiration: number): EventNotifications{
    let message = daysToExpiration > 1 ? 'Expiration in ' + daysToExpiration + ' days' : 'Expiration in ' + daysToExpiration + ' day';
    if(daysToExpiration === 0){
      message = 'Event EXPIRED';
    }

    if(this.app){
      LocalNotifications.schedule({
        notifications:[
          {
            title : message,
            body : notificationBody,
            id: notificationId,
            schedule: { at: notificationDate},
          },
        ]
      }).catch(e => this.presentAlert(e));
    }

    const eventNotification: EventNotifications = {
      notificationTitle: message,
      notificationBody,
      notificationID: notificationId,
      notificationDate: moment(notificationDate).valueOf(),
      notificationScheduled: false,
      notificationEmails: this.settings.notifications,
    };

    console.log('event notification in add: ', eventNotification);
    console.log('this settings: ', this.settings);

    return eventNotification;
  }

  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  // First show alert -> if OK execute function
  async presentAlert(message: string) {

    const alert = await this.alertController.create({
      header: 'Oooops',
      message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }


  private defaultSettings(){
    this.settings = {
      dateFormat: '1',
      timeFormat: '1',
      greenZone: 20,
      orangeZone: 10,
      redZone: 5,
      notifications: [],
      notificationTime: '10:00',
      filter: [true, false, false], // (active, expired, done)
      initLoad: 10,
      nightMode: '3',
      linkedAccount: '',
      network: '1',
      editTimestamp: 0,
      user: [0,0]
    };
    this.filters = this.settings.filter;
    this.eventsToLoad = this.settings.initLoad;
  }

  private setToDb(key: string, value: EventModel, origin?: string): Promise<any>{
    return this.db.writeToDb('Users/' + this.auth.getUID().value + '/events/' + key, value).then(() => {

      if(value.markedAsDone && value.markedAsDone > 0){
        this.cancelNotifications(value.notificationId);
      }else{
        if(origin !== 'notifications'){
          this.calculateNotifications(value.notificationId, value.name, value.expirationTimestamp, key);
        }
      }

      this.saving.next(false);
    }).catch(e => {throw new Error(e);});
  }

  private setToStorage(key: string, value: EventModel, origin?: string): Promise<any>{
    return this._storage?.set(key, value).then(() => {
      if(key !== 'settings'){

        if(value.markedAsDone > 0){ // done, expired or deleted
          this.cancelNotifications(value.notificationId);
        }else{
          if(origin !== 'notifications'){
            this.calculateNotifications(value.notificationId, value.name, value.expirationTimestamp, key);
          }
        }
        if(!this.notificationIds.find(id => id === value.notificationId)){
          this.notificationIds.push(value.notificationId);
        }
        this.saving.next(false);
      }
    }).catch(e => this.presentAlert(e));
  }

  private addEventToEvents(event: BasicEventModel){

    const eventIndex = this.temporaryEvents.findIndex(x => x.timestamp === event.timestamp);
    if(eventIndex > -1){
      this.temporaryEvents[eventIndex] = event;
      this.temporaryEvents.sort((a,b) => a.expirationTimestamp - b.expirationTimestamp);
      clearTimeout(this.filterEventsTimer);
      this.filterEventsTimer = setTimeout(() => {
        this.events.next(this.temporaryEvents);
      },50);
    }else{
      this.temporaryEvents.push(event);
      this.temporaryEvents.sort((a,b) => a.expirationTimestamp - b.expirationTimestamp);
      clearTimeout(this.filterEventsTimer);
      this.filterEventsTimer = setTimeout(() => {
        this.events.next(this.temporaryEvents);
      },50);
    }
  }

  private removeFromEvents(timestamp: string){

    const eventIndex = this.temporaryEvents.findIndex(x => x.timestamp === timestamp);
    if(eventIndex > -1){
      const events = this.temporaryEvents;
      events.splice(eventIndex,1);
      clearTimeout(this.filterEventsTimer);
      this.filterEventsTimer = setTimeout(() => {
        this.events.next(events);
      },50);
    }
  }
}
