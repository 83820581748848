import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, takeWhile } from 'rxjs';
import { StorageService } from 'src/app/shared/storage.service';
import { BasicEventModel } from '../event/event.model';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PendingResult } from '@capacitor/local-notifications';
import { ModalController, PopoverController } from '@ionic/angular';
import { AuthComponent } from 'src/app/shared/auth/auth.component';
import { LocationService } from 'src/app/shared/location.service';
import { AdMob, AdMobBannerSize, BannerAdOptions, BannerAdPluginEvents, BannerAdPosition, BannerAdSize } from '@capacitor-community/admob';


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({opacity: 1})),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({opacity: 0, background: '#3880ff', overflow: 'hidden', 'max-height': '0px'}),
        animate(300, style({'max-height': '400px', overflow: 'hidden', opacity: 1}))
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(300, style({opacity: 0, height: '0px'})))
    ])
  ]
})

export class EventsComponent implements OnInit, OnDestroy {
  events = new BehaviorSubject<BasicEventModel[]>([]);
  eventsLoading = true;
  eventSubscription: Subscription;
  addsSubscription: Subscription;
  eventsToLoad: number;
  eventsTotal: number;
  pendingNotifications: PendingResult;
  app = true;
  user = 'offline';
  eventsLoadingTimer: any;
  initEventsLoaded = false;
  loggingIn = true;
  initLoad = true;
  testingPremium: number[];
  bannerShown = false;
  syncInProgress = false;
  initSync = new BehaviorSubject<boolean>(false);

  constructor(
    private storage: StorageService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    private location: LocationService,
  ) { }

  ngOnInit() {

    this.app = this.storage.app;

    this.location.setLocation('home');
    this.storage.network.subscribe(x => {
        this.user = x[1];
        this.loggingIn = this.storage.loggingIn;
    });

    this.storage.initSync.pipe(takeWhile(x => !x, true)).subscribe(x => {
      if(x){
        this.initSync.next(x);
      }
      console.log('init sync: ', this.initSync.value);
    });

    this.storage.syncInProgress.subscribe(x => {
      this.syncInProgress = x;
      console.log('sync in progress: ', this.syncInProgress);
    });
    this.eventsLoadingTimer = setTimeout(() => {},500);

    this.testingPremium = this.storage.settings.user; // just for testing

    this.eventSubscription = this.storage.events.subscribe(x => {
      if(this.initLoad){
        this.eventsLoading = true;
      }

      console.log('events: ',x);
      console.log('events loading: ', this.eventsLoading);
      this.eventsTotal = x.length;
      this.eventsToLoad = this.storage.eventsToLoad;
      this.initEventsLoaded = this.storage.initEventsLoaded;
      this.loggingIn = this.storage.loggingIn;
      console.log('init events loaded: ', this.initEventsLoaded);
      console.log('events total: ', this.eventsTotal);
      console.log('Logging in: ', this.loggingIn);
      console.log('init load: ', this.initLoad);
      console.log('init sync: ', this.initSync.value);
      console.log('sync in: ', this.syncInProgress);

      if(this.eventsTotal > 0){
        clearTimeout(this.eventsLoadingTimer);
        this.eventsLoadingTimer = setTimeout(() => {
          this.loadMore(x);
          if(this.initEventsLoaded && this.initSync.value){
            this.eventsLoading = false;
            this.initLoad = false;
            console.log('loading events: ', this.eventsLoading);
          }
          else if(this.initEventsLoaded && !this.initSync.value){
            this.initSync.pipe(takeWhile(y => !y, true)).subscribe(z => {
              console.log('take until: ', z);
              if (z) {
                this.eventsLoading = false;
                this.initLoad = false;
                console.log('waited for sync finish: ', this.eventsLoading);
              }
            });
          }
        },300);
      }else{
        if(this.initEventsLoaded){
          this.eventsLoading = false;
          this.initLoad = true;
          this.events.next(x);
          console.log('loading events: ', this.eventsLoading);
        }
      }
    });

    this.addsSubscription = this.storage.loadingSettings.subscribe(x => {
      if(!x && this.storage.settings.user[1] === 0 && this.app){
        this.adMobInit().then(() => {
          console.log('banner init');
          this.banner(true);
        });
      }
      if(!x && this.storage.settings.user[1] === 1 && this.app){
        console.log('banner out');
        if(this.bannerShown){
          this.banner(false);
        }
      }
    });
  }

  async adMobInit(): Promise<void>{
    await AdMob.trackingAuthorizationStatus();

    AdMob.initialize({
      requestTrackingAuthorization: true
    });
  }

  async banner(show: boolean): Promise<void>{
    const bannerOptions: BannerAdOptions = {
      // adId: 'ca-app-pub-6115961211121415/7447447236',
      adId: 'ca-app-pub-3940256099942544/6300978111', // TEST ID
      isTesting: true,
      adSize: BannerAdSize.BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
    };

    if(show){
      AdMob.showBanner(bannerOptions);
      this.bannerShown = true;
    }else{
      this.bannerShown = false;
      AdMob.hideBanner();
    }

    // AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    //   console.log('banner loaded');
    // });

    // AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
    //   console.log('banner size changed: ', size);
    // });

  }

  loadMore(events: BasicEventModel[]){
    const batch = events.slice(0,this.eventsToLoad);
    this.events.next(batch);
  }

  onLoadMore(){
    let eventsLoaded = this.eventsToLoad;
    this.eventsToLoad = this.eventsToLoad + this.eventsToLoad;

    while( eventsLoaded < this.eventsTotal && eventsLoaded < this.eventsToLoad){
      this.events.next([...this.events.value, this.storage.events.value[eventsLoaded]]);
      eventsLoaded++;
    }
  }

  doRefresh(event){
    setTimeout(() => {
      this.storage.getEvents().then(() => {
        console.log('getting events');
        if(this.app){
          this.syncInProgress = true;
          this.syncApp();
        }
      }).catch(e => console.log('get events error: ', e));
      event.target.complete();
    });
  }

  ngOnDestroy(){
    this.eventSubscription.unsubscribe();
    this.addsSubscription.unsubscribe();
  }

  async logIn(){
    console.log('login');
    const modal = await this.modalController.create({
      component: AuthComponent,
      componentProps: { info: 'login'},
      cssClass: 'for-settings',
    });
    return await modal.present();
  }

  syncApp(){
    this.storage.syncApp();
  }

  togglePremium(){
    const newUser = this.storage.settings;
    this.testingPremium = [55];
    if(newUser.user[0] === 0){
      console.log('newUser1: ', newUser.user);
      newUser.user[0] = 1;
      console.log('premium is: ', newUser.user);
    }else{
      newUser.user[0] = 0;
      console.log('premium is: ', newUser.user);
    }
    console.log('NewUser2: ', newUser);
    this.storage.setSettings('settings', newUser).then(() => {
      this.testingPremium = this.storage.settings.user;
      console.log('After set: ', this.storage.settings.user);
    });
  }

  toggleAds(){
    const newUser = this.storage.settings;
    this.testingPremium = [55];
    if(newUser.user[1] === 0){
      newUser.user[1] = 1;
      console.log('ads is: ', newUser.user);
    }else{
      newUser.user[1] = 0;
      console.log('ads is: ', newUser.user);
    }
    console.log('NewUser: ', newUser);
    this.storage.setSettings('settings', newUser).then(() => {
      this.testingPremium = this.storage.settings.user;
      console.log('After set: ', this.storage.settings.user);
    });
  }

}
