import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-setfilters',
  templateUrl: './setfilters.component.html',
  styleUrls: ['./setfilters.component.scss'],
})
export class SetFiltersComponent implements OnInit, AfterViewInit {
  checkedActive: boolean;
  checkedExpired: boolean;
  checkedDone: boolean;
  viewInit = false;
  filters: boolean[]; // [active, expired, done]


  constructor(
    public popoverController: PopoverController,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.loadFilters();
  }

  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  loadFilters(){
    this.filters = this.storage.filters;
    this.checkedActive = this.filters[0];
    this.checkedExpired = this.filters[1];
    this.checkedDone = this.filters[2];
  }

  onCheckbox(name: string){
    if(this.viewInit){
      switch(name){
        case 'active':{
          this.checkedActive = !this.checkedActive;
          break;
        }
        case 'expired':{
          this.checkedExpired = !this.checkedExpired;
          break;
        }
        case 'done':{
          this.checkedDone = !this.checkedDone;
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  onFilter(){
    const filters = [this.checkedActive, this.checkedExpired, this.checkedDone];
    let filtersChanged = false;
    if(this.filters.toString() !== filters.toString()){
      this.storage.setFilters(filters);
      this.storage.getEvents().catch(e => console.log('error: ', e));
      filtersChanged = true;
    }
    this.popoverController.dismiss(filtersChanged,'button');
  }

}
