import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService{
  location = new BehaviorSubject<string>('home');

  constructor(){}

  setLocation(currentLocation: string){
    this.location.next(currentLocation);
  }

}
