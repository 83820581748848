import { AfterViewInit, Component, OnDestroy, OnInit, ɵɵsetComponentScope } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { LocalNotifications } from '@capacitor/local-notifications';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { StorageService } from '../storage.service';
import { SettingsModel } from './settings.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy{
  ionicForm: FormGroup;
  checkedActive: boolean;
  checkedExpired: boolean;
  checkedDone: boolean;
  viewInit = false;
  formChange = false;
  subscription: Subscription;
  timeFormat: any[] = [24, 'HH:mm'];
  notificationTime: string;
  email = false;
  user: string;

  validationMessages = {
    greenZone: [
      { type: 'required', message: 'Field is required.' },
      { type: 'customZonesValidator', message: 'Must be at least 20.' },
      { type: 'customZonesRangeValidator', message: 'Must be more than Orange zone.' }
    ],
    orangeZone: [
      { type: 'required', message: 'Field is required.' },
      { type: 'customZonesRangeValidator', message: 'Must be more than Red zone and less than Green zone.' }
    ],
    redZone: [
      { type: 'required', message: 'Field is required.' },
      { type: 'customZonesRangeValidator', message: 'Must be less than Orange zone.' }
    ],
    initLoad: [
      { type: 'required', message: 'Field is required.' },
      { type: 'customLoadValidator', message: 'Must be at least 5.' }
    ],
    notificationEmail: [
      { type: 'required', message: 'Field is required.' },
      { type: 'email', message: 'Must be a valid email.' }
    ],
  };

  constructor(
    public modalController: ModalController,
    public storage: StorageService,
    private formBuilder: FormBuilder,
    public alertController: AlertController,
    public toastController: ToastController,
    private auth: AuthService
    ){}

  ngOnInit(): void {
    this.ionicForm = new FormGroup({
      nightMode: new FormControl(this.storage.settings.nightMode, Validators.required),
      network: new FormControl(this.storage.settings.network, Validators.required),
      greenZone: new FormControl(this.storage.settings.greenZone, Validators.compose([Validators.required, this.customZonesValidator])),
      orangeZone: new FormControl(this.storage.settings.orangeZone, Validators.required),
      redZone: new FormControl(this.storage.settings.redZone, Validators.required),
      timeFormat: new FormControl(this.storage.settings.timeFormat, Validators.required),
      dateFormat: new FormControl(this.storage.settings.dateFormat, Validators.required),
      initLoad: new FormControl(this.storage.settings.initLoad, Validators.compose([Validators.required, this.customLoadValidator])),
      notificationTime: new FormControl(
        moment(this.storage.settings.notificationTime, 'HH:mm').format(this.timeFormat[1]),
        Validators.required
      ),
    },{ validators: this.customZonesRangeValidator});

    this.storage.user.subscribe(x => {
      this.user = x.name;

      if(this.user !== ''){
        this.ionicForm.addControl('notificationEmails', this.formBuilder.array([]));

        if(this.storage.settings.notifications.length > 0){
          this.storage.settings.notifications.forEach(notification => this.onAddMail(notification));
          // Object.values(notification)[0]
        }else{
          this.onAddMail(this.user);
        }
      }

      if(this.storage.app){
        if(this.user !== this.storage.settings.linkedAccount){
          this.ionicForm.addControl('linkedAccount', new FormControl(this.storage.settings.linkedAccount, Validators.required));
        }
      }
    });

    // Checkboxes (active, expired, done)
    this.checkedActive = this.storage.settings.filter[0];
    this.checkedExpired = this.storage.settings.filter[1];
    this.checkedDone = this.storage.settings.filter[2];

    this.notificationTime = this.storage.settings.notificationTime;


    this.subscription = this.ionicForm.valueChanges.subscribe(x => this.formChange = true);

    this.registreLocalNotifications(); //Check if notifications are granted - if not, requests permission
  }


  ngAfterViewInit(): void {
    this.viewInit = true;
  }

  customZonesValidator(fc: FormControl){
    if(fc.value < 20){
      return ({customZonesValidator: true});
    }else{
      return (null);
    }
  }

  customLoadValidator(fc: FormControl){
    if(fc.value < 5){
      return ({customLoadValidator: true});
    }else{
      return (null);
    }
  }

  customZonesRangeValidator( fg: FormGroup){
    const green = parseFloat(fg.get('greenZone').value);
    const orange = parseFloat(fg.get('orangeZone').value);
    const red = parseFloat(fg.get('redZone').value);

    if(green < orange || orange < red){
      if(green < orange){
        fg.get('greenZone').setErrors({customZonesRangeValidator: true});
        fg.get('orangeZone').setErrors({customZonesRangeValidator: true});
      }
      if(orange < red){
        fg.get('orangeZone').setErrors({customZonesRangeValidator: true});
        fg.get('redZone').setErrors({customZonesRangeValidator: true});
      }
      return ({customZonesRangeValidator: true});
    }else{
      if(fg.get('greenZone').errors && fg.get('greenZone').hasError('customZonesRangeValidator')){
        fg.get('greenZone').updateValueAndValidity();
      }
      if(fg.get('orangeZone').errors && fg.get('orangeZone').hasError('customZonesRangeValidator')){
        fg.get('orangeZone').updateValueAndValidity();
      }
      if(fg.get('redZone').errors && fg.get('redZone').hasError('customZonesRangeValidator')){
        fg.get('redZone').updateValueAndValidity();
      }
      return (null);
    }

  }

  onTimeFormatChange(event){
    if(event === 1){
      this.timeFormat = [24, 'HH:mm'];
    }else{
      this.timeFormat = [12, 'hh:mm a'];
    }
  }

  onTimeFocus(event: FocusEvent){
    this.targetElement(event,'focus');
  }

  onTimeBlur(event: FocusEvent){
    this.targetElement(event,'blur');
  }

  targetElement(event: FocusEvent, action: string){
    const target = event.composedPath().filter(x => {
      const element = (x as HTMLElement).nodeName;
      if(element){
        return element.toLowerCase() === 'ion-item';
      }
    });
    if(action === 'focus'){
      (target[0] as HTMLElement).classList.add('item-has-focus');
    }else{
      (target[0] as HTMLElement).classList.remove('item-has-focus');
    }

  }

  onCheckbox(name: string){
    if(this.viewInit){
      switch(name){
        case 'active':{
          this.checkedActive = !this.checkedActive;
          if(this.checkedActive !== this.storage.settings.filter[0] && !this.formChange){
            this.formChange = true;
          }
          break;
        }
        case 'expired':{
          this.checkedExpired = !this.checkedExpired;
          if(this.checkedExpired !== this.storage.settings.filter[1] && !this.formChange){
            this.formChange = true;
          }
          break;
        }
        case 'done':{
          this.checkedDone = !this.checkedDone;
          if(this.checkedDone !== this.storage.settings.filter[2] && !this.formChange){
            this.formChange = true;
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }


  public onAddMail(value: string){
    const emails = this.ionicForm.get('notificationEmails') as FormArray;
    emails.push(this.createEmail(value));
  }

  public onRemoveMail(i: number){
    const emails = this.ionicForm.get('notificationEmails') as FormArray;
    if (emails.length > 1){
      emails.removeAt(i);
    }
  }

  submitForm(){
    const filters: boolean[] = [this.checkedActive, this.checkedExpired, this.checkedDone];
    const notifications: string[] = [];
    // this.ionicForm.value.notificationEmails ? this.ionicForm.value.notificationEmails :
    this.ionicForm.value.notificationEmails.forEach(email => notifications.push(email.notificationEmail));
    let linkedAccount = '';
    if(this.ionicForm.value.linkedAccount){
      linkedAccount = this.ionicForm.value.linkedAccount;
    }
    const settings: SettingsModel = {
      timeFormat: this.ionicForm.value.timeFormat,
      dateFormat: this.ionicForm.value.dateFormat,
      greenZone: this.ionicForm.value.greenZone,
      orangeZone: this.ionicForm.value.orangeZone,
      redZone: this.ionicForm.value.redZone,
      notifications,
      notificationTime: moment(this.ionicForm.value.notificationTime, this.timeFormat[1]).format('HH:mm'), // TODO
      filter: filters,
      initLoad: this.ionicForm.value.initLoad,
      nightMode: this.ionicForm.value.nightMode,
      editTimestamp: moment().valueOf(),
      network: this.ionicForm.value.network,
      linkedAccount,
      user: this.storage.settings.user
    };

    this.storage.setSettings('settings', settings);
    this.presentToast('Good Job!','Settings successfully updated');
    this.modalController.dismiss();
  }

  onClose(){
    if(this.formChange){
      this.presentAlert('Do you want to save changes?', 'Hold on', 'You forgot to save changes!').then(result => {
        if(result === 'confirm'){
          this.submitForm();
        }else{
          this.modalController.dismiss();
        }
      });
    }else{
      this.modalController.dismiss();
    }
  }

  // First show alert -> if OK execute function
  async presentAlert(message: string, header: string, subHeader: string) {

    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: [
        {
          text: 'Nope',
          role: 'cancel',
        },{
          text: 'Yes',
          role: 'confirm',
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }


  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  removeUser(){
    this.storage.removeUser();
    this.auth.removingUser.subscribe(x => {
      if(x === '2'){
        this.modalController.dismiss();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private createEmail(value: string): FormGroup{
    return new FormGroup({
      notificationEmail: new FormControl(value, Validators.compose([Validators.required, Validators.email])),
    });
  }

  private registreLocalNotifications(){
    LocalNotifications.checkPermissions().then(result => {
      if (result.display !== 'granted') {
        LocalNotifications.requestPermissions().then((permission) => {
          if (permission.display !== 'granted') {
            alert('No permission fo notifications');
          }
        });
      }
    });
  }
}
