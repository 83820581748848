import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../storage.service';

const PREMIUM_KEY = 'premium_1';
const NO_ADS_KEY = 'no_ads_1';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./premium.component.scss']
})
export class PremiumComponent implements OnInit{
  ads = true;
  isPremium = false;
  products = new BehaviorSubject<IAPProduct[]>([]);
  productsLoading = true;
  app = true;

  constructor(
    public modalController: ModalController,
    private purchase: InAppPurchase2,
    private storage: StorageService,
    public alertController: AlertController
  ){
    if(this.storage.app){
      if(this.storage.settings.user[0] !== 1 || this.storage.settings.user[0] !== 1){
        if(!this.storage.productsRegistered){
          this.registerProducts();
        }
        this.setupListeners();
      }
    }else{
      this.productsLoading = false;
      this.app = false;

      if(this.storage.settings.user[0] === 1){
        this.isPremium = true;
      }
      if(this.storage.settings.user[1] === 1){
        this.ads = false;
      }
    }
  }

  ngOnInit(){
    if(this.storage.app){
      this.purchase.ready(() => {
        console.log('purchase is ready: ', this.purchase.products);
        this.products.next(this.purchase.products);
        console.log('products: ', this.products);
        this.productsLoading = false;
      });
    }
  }

  registerProducts() {
    console.log('registering products');

    if(this.storage.settings.user[0] !== 1){
      this.purchase.register({
        id: PREMIUM_KEY,
        type: this.purchase.NON_CONSUMABLE,
      });
    }

    if(this.storage.settings.user[1] !== 1){
      this.purchase.register({
        id: NO_ADS_KEY,
        type: this.purchase.NON_CONSUMABLE,
      });
    }

    this.storage.productsRegistered = true;
    this.restore();
  }

  setupListeners(){
    this.purchase.when('product').approved(
      (p: IAPProduct) => {
        if(p.id === PREMIUM_KEY) {
          this.isPremium = true;
          const newSettings = this.storage.settings;
          newSettings.user[0] = 1;
          this.storage.setSettings('settings', newSettings);
          console.log('Premium already bought');
        } else if( p.id === NO_ADS_KEY) {
          this.ads = false;
          const newSettings = this.storage.settings;
          newSettings.user[1] = 1;
          this.storage.setSettings('settings', newSettings);
          console.log('ads already bought');
        }

        return p.verify();
      }
    ).verified((p: IAPProduct) => p.finish());
  }

  onPurchase(product: IAPProduct){
    this.purchase.order(product).then(p => {
      // Purchase in progress
      console.log('Purchasing: ', p);
    }, e => {
      this.presentAlert('Purchase was not successful: ' + e);
    });
  }

  restore(){
    this.purchase.refresh();
  }

  // First show alert -> if OK execute function
  async presentAlert(message: string) {

    const alert = await this.alertController.create({
      header: 'Ooops',
      message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }


  onClose(){
    this.modalController.dismiss();
  }
}
