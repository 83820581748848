import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { SettingsComponent } from '../settings/settings.component';
import { InfoComponent } from '../info/info.component';

import { AuthService } from '../auth.service';
import { AuthComponent } from '../auth/auth.component';
import { StorageService } from '../storage.service';
import { PremiumComponent } from '../premium/premium.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  userStatus: string;
  user: string;
  canLogIn = false;
  network: string[];
  userStatusSubscription: Subscription;
  userSubscription: Subscription;
  networkSubscription: Subscription;
  app = true;

  constructor(
    public popoverController: PopoverController,
    public modalController: ModalController,
    public auth: AuthService,
    public toastController: ToastController,
    private storage: StorageService,
  ){}

  ngOnInit() {
    this.app = this.storage.app;
    this.userStatusSubscription = this.auth.userStatus.subscribe(x => this.userStatus = x);
    this.userSubscription = this.auth.user.subscribe(user => this.user = user);
    this.canLogIn = this.auth.canLogIn;
    this.networkSubscription = this.storage.network.subscribe(x => this.network = x);
  }

  async toSettings(){
    const modal = await this.modalController.create({
      component: SettingsComponent,
      cssClass: 'for-settings',
    });
    this.popoverController.dismiss();
    this.unsub();
    return await modal.present();
  }

  async toInfo(){
    const modal = await this.modalController.create({
      component: InfoComponent,
      componentProps: { info: 'info'},
      cssClass: 'for-settings',
    });
    this.popoverController.dismiss();
    this.unsub();
    return await modal.present();
  }

  async toPremium(){
    const modal = await this.modalController.create({
      component: PremiumComponent,
      cssClass: 'for-settings',
    });
    this.popoverController.dismiss();
    this.unsub();
    return await modal.present();
  }

  async logIn(){
    console.log('login');
    const modal = await this.modalController.create({
      component: AuthComponent,
      componentProps: { info: 'login'},
      cssClass: 'for-settings',
    });
    this.popoverController.dismiss();
    this.unsub();
    return await modal.present();
  }

  async toUser(){
    console.log('user');
    const modal = await this.modalController.create({
      component: InfoComponent,
      componentProps: { info: 'user'},
      cssClass: 'for-settings',
    });
    this.popoverController.dismiss();
    this.unsub();
    return await modal.present();
  }

  async presentToast(header: string, message: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

  logOut(){
    this.auth.logOut();
    this.popoverController.dismiss();
    this.unsub();
    this.presentToast('Logged Out', 'Hope to see you soon');
  }

  unsub(){
    this.userStatusSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
  }

  ngOnDestroy(): void {
      this.unsub();
  }
}
