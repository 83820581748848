import { Component, OnInit } from '@angular/core';
import { PushNotifications, Token, PushNotificationSchema } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(
  ) { }

  ngOnInit(): void {
    console.log('Initializing HomePage');
    this.registreLocalNotifications();

    // if(Capacitor.getPlatform() !== 'web'){
    //   this.registerPush();
    // }
  }

  // private registerPush() {
  //   PushNotifications.requestPermissions().then((permission) => {
  //     if (permission.receive === 'granted') {
  //       console.log('permission: ', permission.receive);
  //       // Register with Apple / Google to receive push via APNS/FCM
  //       PushNotifications.register();
  //     } else {
  //       alert('No permission');
  //       // No permission for push granted
  //     }
  //   });

  //   PushNotifications.addListener(
  //     'registration',
  //     (token: Token) => {
  //       console.log('My token: ' + JSON.stringify(token));
  //     }
  //   );

  //   PushNotifications.addListener('registrationError', (error: any) => {
  //     console.log('Error: ' + JSON.stringify(error));
  //   });

  //   PushNotifications.addListener(
  //     'pushNotificationReceived',
  //     async (notification: PushNotificationSchema) => {
  //       console.log('Push received: ' + JSON.stringify(notification));
  //     }
  //   );
  // }

  private registreLocalNotifications(){
    LocalNotifications.checkPermissions().then(result => {
      if (result.display !== 'granted') {
        LocalNotifications.requestPermissions().then((permission) => {
          if (permission.display !== 'granted') {
            alert('No permission fo notifications');
          }
        });
      }
    });
  }
}
