import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { StorageService } from '../storage.service';
import { Platform } from '@angular/cdk/platform';
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter{
  constructor(private storage: StorageService, private platform: Platform){
    super('en-GB', platform);
  }

  public parse(value: any): Date {
    const format = (this.storage.settings.dateFormat === '1') ? 'DD.MM.YYYY' : 'MM.DD.YYYY';
    return new Date(moment(value, format).toString());
  }

  public format(date: Date): string{
    const format = (this.storage.settings.dateFormat === '1') ? 'DD.MM.YYYY' : 'MM.DD.YYYY';
    return moment(date).format(format);
  }
}
