import { Component, OnInit } from '@angular/core';
import { LocationService } from '../shared/location.service';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {

  constructor(private location: LocationService) {
  }

  ngOnInit() {
    console.log('setting location');
    this.location.setLocation('policies');
  }

}
