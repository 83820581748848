import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AlertController } from '@ionic/angular';
import { EmailAuthProvider } from 'firebase/auth';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userStatus = new BehaviorSubject<string>('loggedOut');
  user = new BehaviorSubject<string>('');
  canLogIn = false;
  removingUser = new BehaviorSubject<string>('0');
  private userID = new BehaviorSubject<string>('');

  constructor(
    private auth: AngularFireAuth,
    private alertController: AlertController
  ) {
    this.auth.authState.subscribe(authRes => {
      if(authRes){
        this.userStatus.next('loggedIn');
        this.user.next(authRes.email);
        this.userID.next(authRes.uid);
        console.log('user loogged in');
      }else{
        this.userStatus.next('loggedOut');
        this.user.next('');
        this.userID.next('');
        console.log('user logged out');
      }
    });
  }

  getUID(): BehaviorSubject<string>{
    return this.userID;
  }

  logIn(username: string, password: string): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.auth.signInWithEmailAndPassword(username, password).then(res => {
        console.log('res: ',res);
        resolve(res);
      }).catch(error => {
        console.log('error: ', error);
        reject(error);
      });
    });
  }

  signUp(username: string, password: string): Promise<any>{
    return new Promise<any>((resolve, reject) => {
      this.auth.createUserWithEmailAndPassword(username, password).then(res => {
        console.log('res: ',res);
        resolve(res);
      }).catch(error => {
        console.log('error: ', error);
        reject(error);
      });
    });
  }


  logOut(){
    return new Promise((resolve, reject) => {
      if(this.auth.currentUser) {
        this.auth.signOut().then(() => {
          console.log('logged out');
          console.log('logged out after: ', this.auth.currentUser);
        }).catch(error => {
          console.log('error: ', error);
        });
      }
    });
  }

  resetPassword(email: string){
    return new Promise((resolve, reject) => {
      this.auth.sendPasswordResetEmail(email).then(res => {
        console.log('password reset: ',res);
        resolve(res);
      }).catch( error =>{
        console.log('password reset error: ', error);
        reject(error);
      });
    });
  }

  reauthUser(password: string): Promise<string>{
    const credential = EmailAuthProvider.credential(this.user.value, password);
    return new Promise((resolve, reject) => {
      this.auth.currentUser.then(user => {
        user.reauthenticateWithCredential(credential).then(x => {
          console.log('REAUTH', x);
          console.log('User can be deleted');
          resolve('success');
        }).catch(error => {
          console.log('reauth error: ', error.code);
          reject(error.code);
        });
      });
    });
  }

  removeCurrentUser(){
    return new Promise((resolve, reject) => {
      this.auth.currentUser.then(user => {

        this.removingUser.subscribe(x => {
          if(x === '0'){
            console.log('starting removing');
            this.removingUser.next('1');
          }else if(x === '1'){
            console.log('removing...');
          }else if(x === '2'){
            // user delete
            user.delete();

            console.log('user deleted');

            resolve('user deleted');
          }
        });
      }).catch(error => {
        console.log('delete user error: ', error);
      });
    });
  }

  async presentAlertPrompt(header: string, subHeader: string, message: string) {

    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Your PASSWORD'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },{
          text: 'Confirm',
          role: 'confirm',
          handler: data => {
            this.reauthUser(data.password).then(x => {
              console.log('REAUTH: ', x);
              // remove user
              this.removeCurrentUser();
            }).catch(reason => {
              if( reason === 'auth/wrong-password') {
                this.presentAlertPrompt('Ooops', 'The password was not correct', 'Enter your password:');
              }else if(reason === 'auth/too-many-requests'){
                this.presentAlert('Account has been temporarily disabled due to many failed login attempts');
              }else{
                console.log('error ', reason);
              }
            });
            //console.log('HANDLER: ', data.password);
          }
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }

  async presentAlert(message: string) {

    const alert = await this.alertController.create({
      header: 'Oooops',
      message,
      buttons: [
        {
          text: 'OK',
          role: 'confirm',
        }]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role;
  }
}
